//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// $primary: $purple !default;
// $secondary: #82868b !default;
// $info: $blue !default;
// $warning: $orange !default;
// $light: $gray-50 !default;
// $dark: #4b4b4b !default;

$primary: #002845;

/** these do not work yet, the do not exist yet */
$primary-1: #FF826F;
$primary-2: #F0F2F8;
$primary-3: #FFFFFF; // with border
$primary-4: #FFFFFF; // no border